
.App {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: "Rubik";
  font-weight: 400;
  src: url("./../public/Rubik-VariableFont_wght.ttf") format(truetype);
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  src: url("./../public/Montserrat-VariableFont_wght.ttf") format(truetype);
}
@font-face {
  font-family: 'DubaiFont';
  src: url('./../public/Dubai-Bold.ttf');
  font-weight: 400;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
