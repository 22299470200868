.conatainer{
    min-width: 639px;
    max-width: 640px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    border-radius: 12px;
    gap: 15px;
    max-height: calc(100% - 70px);
    overflow-y: hidden;
    border-radius: 12px;
    border: 1px solid var(--Border-Container, #E6E6E6);
    background: #FFF;
    margin-top: 30px;
    margin-bottom: 30px;
}

.conataineSeller{
    min-width: auto;
    max-width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    border-radius: 12px;
    gap: 15px;
    max-height: calc(100% - 70px);
    overflow-y: auto;
    border-radius: 12px;
    border: 1px solid var(--Border-Container, #E6E6E6);
    background: #FFF;
    margin-top: 30px;
    margin-bottom: 30px;
}
.conatainer::-webkit-scrollbar {
    width: 10px;
}
.progress-container{
    padding-bottom: 0;
    display: flex;
    width: 100%;
    align-items: center;
}
.progress-value{
padding-left: 12px;
padding-right: 12px;
color: var(--Pickled-Bluewood-700, #3C4D68);
font-family: Rubik;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */

}
.header-text{
    width: 100%;
    text-align: start;
    padding-top: 0;
    padding-bottom: 0;
    color: var(--Grayscale-Primary, #4D4D4D);
    font-family: Rubik;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 39.2px */
}
.header-text:dir(rtl){
    font-family: DubaiFont;
  }
.btn-container{
    direction: ltr;
    padding-top: 0;
    width: 100%;


}
.green-btn{
width: 100%;
display: flex;
padding: 16px 32px;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
border-radius: 12px;
background: var(--Brand-Green, #52B44A);
color: #FFF;
font-size: 16px;
font-style: normal;
font-family: Montserrat;
font-weight: 700;
line-height: normal;
letter-spacing: 0.32px;
}
.green-btn:dir(rtl){
    font-family:DubaiFont;
}
.white-btn{
    width: 100%;
    display: flex;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid;
    align-self: stretch;
    border-radius: 12px;
    background: var(--Brand-Green, #FFF);
    color: #52B44A;
    font-size: 15px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 1000;
    line-height: normal;
    letter-spacing: 0.32px;
    }
    .white-btn:dir(rtl){
        font-family:DubaiFont;
    }
.info{
  display: flex;
  flex-direction: column;
  padding-top: 0;
}
.info-text{
text-align: start;
color: var(--Grayscale-Primary, #4D4D4D);
padding-bottom: 12px;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 140%; /* 22.4px */
align-self: stretch;
}

.info-text:dir(rtl){
    font-family: DubaiFont;
  }


.progress-value-200{
  width: 95%;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: var(--Brand-Green, #52B44A)
}
.info-container{
margin-top: 8px;
display: flex;
padding: 16px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 20px;
align-self: stretch;
border-radius: 12px;
border: 1px solid var(--Border-Container, #E6E6E6);
background: var(--Background, #F9FAFB);
}
.row{
    display: flex;
    justify-content: center;
    align-items: center;
}
.row>p{
    text-align: start;
margin-left: 12px;
margin-right: 12px;
color: var(--Grayscale-Secondary, #696969);
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
}
.row>p:dir(rtl){
    font-family: DubaiFont;
  }
.text{
    padding-top: 0;
    padding-bottom: 0;
    color: var(--Grayscale-Secondary, #696969);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    text-align: start;
}
.text:dir(rtl){
    font-family: DubaiFont;
  }
@media only screen and (max-width: 600px){
    .conatainer{
        /* max-height: 70vh; */
    }
}
@media only screen and (max-width: 720px){
    .conatainer{
        min-width: auto;
    }
}
