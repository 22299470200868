.background{
    display: flex;
    flex-direction: column;
    background-position: center;
    background-color:  #F4F5F7;
    display: flex;
    padding: 20px;
    padding-top: 0;
    padding-bottom: 0;
    justify-content: center;
    align-items: center;
    /* min-height: 100vh; */
    min-height: 100%;
    /* height: 100%; */
   
}
.header{
    position: absolute;
    top:0px;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.languageBar{
    margin-left: 30px;
    margin-right: 30px;
    font-size: x-large;
    cursor: pointer;
    color: #007aff;
}
.logo{
    width: 88px;
    height: 44px;
    margin-right: 140px;
    margin-left: 140px;
    margin-top: 71px;
}
.public-background{
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-position: center;
    background-image:  url('./../imgs/photo_2023-11-09_17-40-33.jpg');
    /* min-height: 100vh; */
    height: 100%;
    width: 100%;
}
.header-publick{
  
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 600px) {

    
    
    .logo{
        width: 88px;
        height: 44px;
        margin-right: 16px !important;
        margin-left: 16px !important;
        margin-top: 12px !important;
    }
  }

  @media only screen and (max-width: 1024px) {
    .logo{
        width: 88px;
        margin-left: 70px;
        margin-top: 40px;
    }
  }