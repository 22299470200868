@import "rsuite/dist/rsuite.css";
.Step1 {
  justify-content: center;
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 140px;
  margin-right: 140px;
  /* height: calc(100vh - 115px); */
}
.carouselItem {
  display: flex;
  flex-direction: column;
  object-fit: contain;
  padding: 10px;
}

.carouselItem__img {
  border-radius: 10px;
  margin-bottom: 5px;
  box-shadow: 0px 0px 5px black;
}

.values-icon-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.header-texts {
  display: flex;
  gap: 12px;
  max-width: 100%;
  flex-direction: column;
}
.radio-wrapper-row {
  display: flex;
  gap: 12px;
}
.header-texts > p {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.inputs {
  box-sizing: border-box;
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: rgba(248, 248, 248, 0.8);
  padding-left: 10px;
  width: 100%;
  height: 60px;
  padding-left: 10px;
  flex-shrink: 0;
}
.pforStep1 {
  text-wrap: wrap;
  max-width: 884px;
  color: #000;
  font-family: SF Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
  text-align: justify;
  letter-spacing: 0.48px;
  margin-bottom: 12px;
}
.arabic > label {
  left: auto;
  margin-right: 30px;
}
.css-1jqq78o-placeholder {
  text-align: start;
  grid-area: 1/1/2/3;
  color: hsl(0, 0%, 50%);
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
}

.input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  width: 4cqb;
  height: 20px;
  border-width: thin;
}
.otherContainer {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}

.texts {
  justify-content: end;
  display: flex;
  margin-top: 16px;
}

.dontChecked {
  margin-top: 10px;
  margin-right: 20px;
  cursor: pointer;
  display: inline-flex;
  padding: 17px 52px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #f0f0f0;
}

.checked {
  margin-top: 10px;
  margin-right: 20px;
  display: inline-flex;
  padding: 17px 52px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #00c7ae;
  background: #adede5;
}
.items-wrapper {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
}
.bottom-info {
  text-align: start;
  max-width: 697px;
  text-wrap: wrap;
  color: #000;
  font-family: SF Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 274;
  line-height: 28px; /* 116.667% */
}
.info-p2 {
  text-wrap: wrap;
  text-align: justify;
  max-width: 1050px;
  color: #000;
  font-family: SF Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
  letter-spacing: 0.48px;
}
.main {
  justify-content: flex-start !important;
  flex-direction: row;
  /* justify-content: flex-start; */
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  align-items: flex-start;
}
.childDiv {
  /* margin-right: 30px; */
  display: flex;
  flex-direction: column;
}
.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.titleforinputs {
  color: #000;
  font-family: SF Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
  letter-spacing: 0.48px;
}
.info-for-more-info {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-left: 140px;
  margin-right: 140px;
  height: calc(100vh - 115px);
  justify-content: center;
}
.list {
  justify-content: space-between;
  border: 1px;
  max-width: 700px;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
  flex-direction: row;
}
.line {
  opacity: 0.1;
  background: #000;
  width: 100%;
  height: 1px;
  flex-shrink: 0;
  margin-left: 85.5;
  margin-left: 0px;
  margin-right: 0px;
}

.btn-container {
  gap: 24px;
  display: flex;
  width: 100%;
}
.selects {
  padding-left: 4px;
  margin-bottom: 10px;
  width: 100%;
  height: 64px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: rgba(248, 248, 248, 0.8);
}
.radio-wraper {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
}
.radio-wraper > div {
  display: flex;
  width: 100%;
  gap: 12px;
}
.top-input-container {
  flex-direction: column;
  width: 700px;
  display: flex;
}

.workinfobtns {
  display: flex;
}
.workinfobtnscontainer {
  margin-bottom: 5px;
}
.pfortitle {
  margin-top: 0;
  margin-bottom: 5px;
  text-align: justify;
  color: #000;
  font-family: inherit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 180% */
  letter-spacing: 0.4px;
}
.input-range {
  height: 8px;
  border-radius: 10px;
  max-width: 512px;
  background: #00c7ae;
}
.salary-wrapper {
  padding-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.checkBoxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.checkBox {
  margin-right: 16px;
  margin-left: 16px;
}
.container {
  max-width: 512px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
}
.montly-title {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}
.input-form {
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  width: 412px;
}
.MuiStack-root {
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  width: 412px;
}
.MuiInputBase-root.MuiOutlinedInput-root {
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: rgba(248, 248, 248, 0.8);
  border-radius: 8px !important;
}

.rightpart {
  max-width: 500px;
  display: flex;
}
.range-limit {
  width: 95%;
  display: flex;
  justify-content: space-between;
}
.selection-container-second {
  gap: 8px;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.liabilites-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}
.size-controled {
  width: 640px;
}
.green-text-wrapper {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Brand-Green, #52b44a);
  background: rgba(82, 180, 74, 0.04);
}
.green-text-wrapper > p {
  text-align: start;
  color: var(--Brand-Green, #52b44a);
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}
.green-text-wrapper > span {
  text-align: start;
  color: var(--Grayscale-Primary, #4d4d4d);
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
.green-text-wrapper > p:dir(rtl) {
  font-family: DubaiFont;
}
.green-text-wrapper > span:dir(rtl) {
  font-family: DubaiFont;
}
.last-text {
  width: 100%;
  color: var(--Grayscale-Primary, #4d4d4d);
  text-align: center;
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
}
.last-text:dir(rtl) {
  font-family: DubaiFont;
}
.check-icon {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid var(--Brand-Green, #52b44a);
}
.check-icon-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}
.last-text-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.last-text-wrapper > p {
  width: 100%;
  text-align: center;
  color: var(--Grayscale-Primary, #4d4d4d);
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
.last-text-wrapper > p:dir(rtl) {
  font-family: DubaiFont;
}
.green-text-limit {
  width: 100%;
  text-wrap: nowrap;
  color: var(--Brand-Green, #52b44a);
  text-align: center;
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
}
.last-info-wrapper {
  flex-direction: column;
  display: flex;
  width: 100%;
  gap: 33px;
}
.last-info-wrapper > p {
  width: 100%;
  color: var(--Grayscale-Primary, #4d4d4d);
  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
.gray-label {
  display: flex;
  padding: 16px var(--spacing-2xl, 20px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-2xl, 20px);
  align-self: stretch;
  border-radius: var(--radius-xl, 12px);
  border: 1px solid var(--Border-Container, #e6e6e6);
  background: #f9f9f9;
}
.gray-label > p {
  text-align: start;
  color: var(--Grayscale-Primary, #4d4d4d);
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 21.6px */
}
.carusel {
  width: 100%;
  display: flex;
  overflow: auto;
}
.img-slider {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.button-wrapper {
  bottom: 72%;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: end;
  top: 38%;
}
.apartament-info-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  display: flex;
  gap: var(--spacing-3xl, 24px);
}
.error {
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
  text-align: start;
  color: red;
}
.basic-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-lg, 12px);
  width: 100%;
}
.header-basic {
  color: var(--Grayscale-Primary, #4d4d4d) !important;
  font-family: Rubik;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}
.basic-info > div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.basic-info > div > p {
  color: var(--Grayscale-Secondary, #696969);
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
}
.basic-info > div > span {
  margin-right: 5px;
  margin-left: 5px;
  text-align: start;
  color: var(--Grayscale-Secondary, #696969);
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
.pair-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
@media (max-width: 1024px) {
  .Step1 {
    /* height: calc(100% - 51px); */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 70px;
    justify-content: center;
  }
  .info-for-more-info {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-left: 70px;
    margin-right: 70px;
    height: calc(100vh - 115px);
    justify-content: center;
  }
  .input-form {
    display: flex;
    flex-direction: column;
    width: 412px;
  }
}
@media only screen and (max-width: 735px) {
  .top-input-container {
    width: 100%;
  }
  .input-form {
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }
  .size-controled {
    width: 100%;
  }
  .values-icon-container {
    flex-wrap: wrap;
  }
}

@media (max-width: 600px) {
  .apartament-info-container {
    flex-wrap: wrap;
  }

  .bottom-info {
    text-align: start;
    max-width: 697px;
    text-wrap: wrap;
    color: #000;
    font-family: inherit;
    font-size: 24px;
    font-style: normal;
    font-weight: 274;
    line-height: 28px; /* 116.667% */
  }
  .list {
    justify-content: flex-start;
    border: 1px;
    max-width: 700px;
    flex-wrap: wrap;
    /* align-items: center; */
    display: flex;
  }
  .radio-wrapper-row {
    flex-direction: column;
  }
  .checkBox {
    margin-right: 16px;
    margin-left: 0;
  }
  .rightpart {
    margin-top: 10px;
    flex-direction: column;
    width: 100%;
    /* max-width: 500px; */
    display: flex;
    margin-bottom: 10px;
  }

  .checked {
    width: 100%;
    margin-right: 8px;
    display: inline-flex;
    padding: 15px 23px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #00c7ae;
    background: #adede5;
  }

  .dontChecked {
    width: 100%;
    margin-right: 8px;
    cursor: pointer;
    display: inline-flex;
    padding: 15px 23px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #f0f0f0;
  }
  .main {
    width: 100%;
    justify-content: flex-start !important;
    flex-direction: row;
    /* justify-content: flex-start; */
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    align-items: baseline;
  }
  .input-form {
    margin-right: 0px !important;
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }
  .MuiStack-root {
    margin-right: 0px;
    width: 100% !important;
  }
  .otherContainer {
    width: 100%;
  }
  .childDiv {
    width: 100%;
    /* margin-right: 30px; */
    display: flex;
    flex-direction: column;
  }
  .info-p2 {
    color: #000;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  .bottom-info {
    color: #000;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  .info-for-more-info {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-left: 16px !important;
    margin-right: 16px !important;
    justify-content: end !important;
  }
  .titleforinputs {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #000;
    font-family: inherit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 150% */
    letter-spacing: 0.48px;
  }
  .pforStep1 {
    text-wrap: wrap;
    max-width: 884px;
    color: #000;
    font-family: inherit;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  .Step1 {
    /* height: 10% !important; */
    display: flex;
    justify-content: end;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 16px !important;
    margin-right: 16px !important;
    justify-content: end !important;
  }
  .container {
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    /* max-width: 512px; */
    margin-top: 32px;
    display: flex;
  }
  .btnsContainer {
    display: flex;
    flex-direction: row;
  }
}
.salary-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.tooltip-container {
  margin-left: 8px; 
}

.salary-fields-wrapper {
  margin-top: 20px;
}

.tooltip-outer-container {
  display: flex;
}

.salary-container .tooltip-container i {
  vertical-align: middle; 
}
