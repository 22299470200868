.item-container{
    margin-top: 5px;
    margin-right: 16px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    min-width: 219px;
    height: 161px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #F0F0F0;
}
.progress {
	background: var(--Border-Component, #E1E4EA);
	border-radius: 20px;
	position: relative;
	margin: 15px 0;
	height: 8px;
	width: 100%;
}

.progress-done {
	background: var(--Brand-Green, #52B44A);
	border-radius: 4px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 0;
	opacity: 0;
	transition: 1s ease 0.3s;
}
.selected-item-container{
    margin-top: 5px;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    min-width: 219px;
    height: 161px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #00C7AE;
    background: #ADEDE5;
}
.info-container-item{
    padding: 16px;
}
.icons{
    display: flex;
    width: 100%;
    align-items: flex-start;
}
.text-for-item{
text-align: justify;
text-wrap: wrap;
max-width:159px;
color: #000;
font-family: SF Pro;
font-size: 14px;
font-style: normal;
font-weight: 540;
line-height: 20px; /* 142.857% */
letter-spacing: 0.28px;
}
.css-13cymwt-control{
    margin-top: 10px;
    background: rgba(248, 248, 248, 0.80) !important;
    border: 1px solid rgba(0, 0, 0, 0.40) !important;
    width: 100%;
    height: 60px;
    border-radius: 8px !important;
}
.css-t3ipsp-control{
    margin-top: 10px;
    width: 100%;
    height: 60px;
    border-radius: 8px !important;
}

.css-1dimb5e-singleValue{
    text-align: start;
}
.css-1u9des2-indicatorSeparator {
    background: none !important;
}
.selection-container{
    gap: 8px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
}
.job-type{
    cursor: pointer;
display: flex;
padding: 5px;
align-items: center;
gap: 12px;
flex: 1 0 0;
min-width: 272px;
max-width: 286px;
border-radius: 12px;
background: var(--Background, #F9FAFB);
}
.icon-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border-radius: 100px;
    background: #FFF;
}
.job-type>p{
    text-align: start;
color: var(--Grayscale-Secondary, #696969);
font-family: Rubik;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%
}
.job-type>p:dir(rtl){
    font-family: DubaiFont;
  }
.selected-job-type{
min-width: 272px;
max-width: 286px;
display: flex;
padding: 5px;
align-items: center;
gap: 12px;
flex: 1 0 0;
border-radius: 12px;
border: 1px solid var(--Brand-Green, #52B44A);
background: #FFF;
box-shadow: 0px 4px 20px 0px rgba(0, 40, 10, 0.12);
}
.input-label{
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
    padding-top: 24px;
}
.select-label{
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
    padding-top: 24px;
}
.input-label>p{
color: var(--Grayscale-Primary, #4D4D4D);
font-family: Rubik;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
}
.input-label>input{
    width: 95%;
    font-size: 16px;
display: flex;
padding: 12px;

align-items: center;
gap: 12px;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--Border-Component, #E1E4EA);
}
.input-label>input:focus{
    border: 2px solid var(--Brand-Green, #52B44A);
    outline: none;
}
.select-label>label:focus-within{
    border: 2px solid var(--Brand-Green, #52B44A);

}

.select-label>label{
    position: relative;
 cursor: pointer;
    width: 100%;
    font-size: 16px;
display: flex;

align-items: center;

align-self: stretch;
border-radius: 8px;
border: 1px solid var(--Border-Component, #E1E4EA);
}
select{
    background: none;
    border-radius: 12px;
    padding: 12px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    height: 100%;
    font-size: 16px;
    border: none;
}

select:focus{
    outline: none;
}
input[type='radio']{
    accent-color: forestgreen;
    width: 20px;
    height: 20px;
    
}
.MuiSlider-root{
    
    border-radius: 12px !important;
    box-sizing: content-box !important;
    display: inline-block !important;
    position: relative !important;
    cursor: pointer !important;
    touch-action: none !important;
    color: #E1E4EA !important;
    -webkit-tap-highlight-color: transparent !important;
    height: 4px !important;

    width: 100% !important;
}
.MuiSlider-track{
    color: #52B44A;
}
.MuiSlider-thumb{
    box-shadow: none !important;
    color: #FFFFFF;
    height: 14px !important;
    width: 14px !important;
    border: 4px solid #52B44A;
        
}
.MuiSlider-mark{
    background-color: #E1E4EA !important;
}
.MuiSlider-markActive{
    background-color: #52B44A !important;
}
.MuiSlider-thumb:before {
    display: 'none',
  }
 .Mui-focusVisible{
   display: none;
  }
.MuiSlider-thumb  .MuiSlider-track {
      border: none;
      background:#52B44A;
}
.MuiSlider-thumb::after{
    height: auto !important;
    width: auto !important;
}
.MuiSlider-thumb::after{
    height: auto !important;
    width: auto !important;
}
.select-label>p{
    color: var(--Grayscale-Primary, #4D4D4D);
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    }
    .select-label>:dir(rtl){
        font-family: DubaiFont;
      }
.selected-icon-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border-radius: 100px;
    background: var(--Brand-Green, #52B44A);
}
.back-button{
    cursor: pointer;
}
p:dir(rtl){
    font-family: DubaiFont;;
}
.radio-button-wrapper{
    cursor: pointer;
    border-radius: 12px;
    background: var(--Background, #F9FAFB);
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
}
.checked-radio-button-wrapper{
    cursor: pointer;
    border-radius: 12px;
    background: #FFFFFF;
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    border: 1px solid var(--Brand-Green, #52B44A);
}
.select-label>label>img{
    pointer-events: none;
    right: 12px;
    position: absolute;
}

.selected-job-type>p{
    text-align: start;
    color: var(--Brand-Green, #52B44A);
font-family: Rubik;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 24px */
}
.selected-job-type>:dir(rtl){
    font-family: DubaiFont;
  }
.input-select-container{
    gap: 12px;
   display: flex;
   width: 100%;
   flex-direction: column;
   align-items: flex-start;
}
.input-select-container>p{
    color: var(--Grayscale-Primary, #4D4D4D);
font-family: Rubik;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
}
.input-select-container>span{
    font-size: 11px;
    text-align: start;
    font-family: DubaiFont;
    opacity: 0.5;
}
.input-select-container>p:dir(rtl){
    font-family: DubaiFont;
  }
.input-select-container>label{
display: flex;
padding: 12px;
align-items: center;
gap: 12px;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--Border-Component, #E1E4EA);
}
.input-select-container>label:focus-within{
    border: 2px solid var(--Brand-Green, #52B44A);

}
.input-select-container>label>input{
    font-size: 16px;
    border: none;
    width: 100%;
    outline: none;
}
.input-select-label{
    justify-content: end;
    width: 63px;
    display: flex;
    align-items: center;
    position: relative;
}
.input-select-label>span{
    font-size: 15px;
}
.input-select-label>select{
    font-size: 16px;
    padding: 0;
    display: flex;
    width: 100%;
}
.input-select-label>img{
    pointer-events: none;
    position: absolute;
    right: -8px;
}
.input-select-label>img:dir(rtl){
    pointer-events: none;
    position: absolute;
    left: -8px;
    right: auto;
}
.rs-picker-toggle-wrapper{
    width: 100%;
}
.rs-input-group-lg > .rs-input {
    padding-bottom: 14px !important;
    height: 46px !important;
    padding: 9px 11px;
    font-size: 16px;
    line-height: 1.375;
}
.liablitie-wrapper{
    
    max-width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: 12px;
    background: var(--Background, #F9FAFB);
    flex-direction: column;
}
.action-wrappers{
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}
.liablitie-header{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}
.action-wrappers>span{
    color: var(--Brand-Green, #52B44A);
    font-family: Rubik;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 19.5px */
}
.icon-title-wrapper{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap:12px;

}
.icon-title-wrapper>span{
    flex: 1 0 0;
color: var(--Grayscale-Primary, #4D4D4D);
font-family: Rubik;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 24px */
}
.icon-title-wrapper>span:dir(rtl){
    font-family: DubaiFont;
  }
.liablitie-content{
    position: relative;
display: flex;
padding: 16px 12px 12px 12px;
flex-direction: column;
align-items: flex-start;
gap: 8px;
align-self: stretch;
border-radius: 7px;
border: 1px solid var(--Border-Component, #E1E4EA);
}
.liablitie-content>span{
    position: absolute;
    top: -11px;
    color: var(--Grayscale-Primary, #4D4D4D);
font-family: Rubik;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 18px */
}
.liablitie{
    justify-content: space-between;
display: flex;
flex-direction: row;
padding: 8px 12px;
align-items: center;
gap: 12px;
align-self: stretch;
border-radius: 5px;
border: 1px solid var(--Border-Component, #E1E4EA);
background: #FFF;
}
.liablitie>p{
text-align: start;
color: var(--Brand-Green, #52B44A);
font-family: Rubik;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 19.5px */
}
.liablitie:dir(rtl){
    font-family: DubaiFont;
  }
.liablitie>img{
    cursor: pointer;
}
.modal-wrapper{
    z-index: 2;
    padding-left: 16px;
    padding-right: 16px;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.24);
    backdrop-filter: blur(8px);
}
.modal-container{
display: flex;
width: 480px;
padding: 20px;
flex-direction: column;
align-items: flex-start;
gap: 40px;
border-radius: 12px;
border: 1px solid var(--Border-Container, #E6E6E6);
background: #FFF;
box-shadow: 0px -0.266px 5.32px 0px rgba(0, 0, 0, 0.03), 0px -0.893px 17.869px 0px rgba(0, 0, 0, 0.04), 0px -4px 80px 0px rgba(0, 0, 0, 0.07);
}
.modal-actions{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.modal-actions>p{
color: var(--Grayscale-Primary, #4D4D4D);
font-family: Rubik;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 140%; /* 28px */
}
.modal-actions>img{
    cursor: pointer;
}
html[dir='rtl']{.MuiSlider-valueLabelLabel:dir(rtl){
    display: inline-block;
    transform: scaleX(-1);
    }
}
.limit-container{

flex-direction: column;
width: 100%;
padding: 0px 20px 16px 20px;
border-radius: 12px;
border: 1px;
gap: 5px;
display: flex;
align-items: center;
justify-content: center;
/* border: 1px solid #E6E6E6; */
/* background: #E6E6E6; */

}
.limit-container>p{
    font-family: Rubik;
font-size: 16px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
    
}
.limit-container>h2{
    font-family: Rubik;
font-size: 27px;
font-weight: 600;
line-height: 45px;
letter-spacing: 0em;
text-align: center;
color: #52B44A;

}
html[dir='rtl']{
.limit-container>h2{
    font-size: 22px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    color: #52B44A;
    
    }
}
.appartament-container{
    align-items: center;
    max-width: 100%;
    display: flex;
    background: linear-gradient(0deg, #E6E6E6, #E6E6E6),
linear-gradient(0deg, #F9F9F9, #F9F9F9);
padding: 16px 20px 16px 20px;
gap: 20px;
border-radius: 12px;
}
.img-show{
width: 96px !important;;
height: 96px;
border-radius: 12px;
background-size: cover;
}
.appartament-container>div{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.appartament-container>div>p{
    font-family: Rubik;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    
}
.appartament-container>div>div{
    gap: 8px;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.appartament-container>div>div>p{
    text-wrap: nowrap;
font-family: Rubik;
font-size: 18px;
font-weight: 600;
line-height: 22px;
letter-spacing: 0px;
text-align: start;
color: #52B44A;
}
.appartament-container>div>div>div{
    align-items: center;
    display: flex;
    gap: 8px;
}
.appartament-container>div>div>div>p{
text-wrap: nowrap;
font-family: Rubik;
font-size: 14px;
font-weight: 500;
line-height: 21px;
letter-spacing: 0px;
text-align: left;

}


@media only screen and (max-width: 635px) {
    .selected-item-container {
    margin-top: 5px;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    width: 95%;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #00C7AE;
    background: #ADEDE5;
    }  

    .item-container{
        margin-top: 5px;
        margin-right: 16px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        width: 95%;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        background: #F0F0F0;
    }
    .text-for-item{
        text-align: justify;
        text-wrap: wrap;
        max-width:159px;
        color: #000;
        font-family: inherit;
        font-size: 14px;
        font-style: normal;
        font-weight: 540;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.28px;
        }
        .job-type{
            min-width: 100%;
        }
        .selected-job-type{
            min-width: 100%;
        }
        .salary-wrapper{
            flex-direction: column !important;
        }
        .liablitie>p{
        max-width: 164px;
        text-align: start;
        color: var(--Brand-Green, #52B44A);
        font-family: Rubik;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 19.5px */
        }
        .liablitie>p:dir(rtl){
            font-family: DubaiFont;
          }
          .appartament-container{
            max-width: 100%;
            display: flex;
            flex-direction: column;
            background: linear-gradient(0deg, #E6E6E6, #E6E6E6),
        linear-gradient(0deg, #F9F9F9, #F9F9F9);
        padding: 16px 20px 16px 20px;
        gap: 20px;
        border-radius: 12px;
        }
        .img-show{
            width: 100% !important;
            height: 128px;
            border-radius: 12px;
            background-size: cover;
            }
        .appartament-container>img{
        width: 100%;
        height: auto;
        border-radius: 12px
        
        }
        .appartament-container>div{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
        .appartament-container>div>p{
            font-family: Rubik;
            font-size: 18px;
            font-weight: 600;
            line-height: 22px;
            letter-spacing: 0px;
            text-align: left;
            
        }
        .appartament-container>div>div{
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        .appartament-container>div>div>p{
            text-wrap: nowrap;
        font-family: Rubik;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: start;
        color: #52B44A;
        }
        .appartament-container>div>div>div{
            align-items: center;
            display: flex;
            gap: 8px;
        }
        .appartament-container>div>div>div>p{
        text-wrap: nowrap;
        font-family: Rubik;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: left;
        
        }
  }


html[dir="rtl"] .select-label>label>img{
        left: 12px;
        right: auto;
}
html[dir="rtl"] .input-select-label>img{
    pointer-events: none;
    position: absolute;
    left: -8px;
    right: auto;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border:0.5px solid var(--Border-Component, #E1E4EA) !important;
}
.css-4ygxeq-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select{
    display: flex !important;
}
.MuiInputBase-root.MuiOutlinedInput-root{
    background: white !important;
    height: 48px;
    border:0.5px solid var(--Border-Component, #E1E4EA) !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: rgba(41, 185, 41, 0.08) !important;
}
.css-4ygxeq-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-4ygxeq-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-4ygxeq-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
   color: var(--Grayscale-Secondary, #696969);
}